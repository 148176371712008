<template>
  <v-card class="!z-20 rounded-lg">
    <v-card-text class="pt-6">
      <v-row>
        <v-col>
          <v-row class="items-start">
            <v-col cols="auto" class="pr-4 pb-6 flex flex-row justify-start">
              <slot name="bank-badge">
                <div v-if="index !== undefined"
                     class="offerNumber"
                     :style="`background-color: ${offerNumberColor(index)}`">
                  <span class="headline-3" :class="index > 2 ? 'text-content' : 'text-tertiary'">
                    {{ index + 1 }}</span>
                </div>
              </slot>
              <LAvatar
                  outline
                  :alt="offer.bankName"
                  :src="bankImgUrl"
                  :fallback-icon="mdiBank"
                  size="52"
                  class="bank-avatar"
                  :data-cy="offer.bankSymbol"
                  :class="{'max-md:-ml-3 md:-ml-2': index !== undefined, 'ml-1' : props.showPlaceholders}"
              />
            </v-col>
            <v-col class="pl-0 max-md:max-w-[560px] md:w-full md:mr-[220px]">
              <h2 class="headline-3 mr-1">
                {{ offerCardTitle }}
              </h2>
              <slot name="offerTitle"  :readonly="readonly" :offer="offer">
                <h2 class="text-medium-emphasis body-1">
                  {{ offer.title }}
                </h2>
              </slot>
            </v-col>
            <SimulationOfferCounter
                v-if="!props.showPlaceholders"
                class="max-md:hidden md:block absolute top-7 right-5"
                :counter="offer.loanApplicationsCount"
            />
          </v-row>
          <v-row :class="{'max-md:mb-4': !areDetailsVisible}">
            <v-col cols="12" class="pl-4 pt-6 md:flex md:flex-row">
              <div class="max-md:grid max-md:grid-cols-2 md:flex md:flex-row md:grow">
                <!--Po bożemu cols=6 i sm=auto wtedy auto nie działa jak powinno i 4 kolumny nie dziela sie na 11/12 pozostałych,
                                jak dam sm=false to wgl też nie działa jak w docsach (bug Vuetify)-->
                <div class="v-number-container flex flex-row items-start md:basis-1/4"
                     :class="{'bottom-border-mdAndUp': !areDetailsVisible && !props.showPlaceholders}">
                  <div>
                    <p class="headline-2 flex flex-row items-center" v-if="!props.showPlaceholders">
                      <template v-if="!isInterestRateFixed && props.type === ProductType.CASH">
                        {{ initialInterestRateSum }}
                      </template>
                      <template v-else-if="!isInterestRateFixed">
                        Zmienne
                      </template>
                      <template v-else-if="isInterestRateFixed">
                        Stałe
                        <span class="chip body-1 ml-2" size="small">
                          {{ numberOfYearsOnFixedInterestRate }} lat
                        </span>
                      </template>
                    </p>
                    <text-placeholder v-else/>
                    <div class="body-1">
                      {{ RATE_OF_INTEREST }}
                      <l-tooltip :max-width="300">
                        <template #activator>
                          <v-icon
                              :icon="mdiInformationOutline"
                              class="mb-1"
                              :size="16"
                          />
                        </template>
                        {{ getMortgageElementDescription(RATE_OF_INTEREST) }}
                      </l-tooltip>
                    </div>
                    <p v-if="areDetailsVisible" class="body-2 text-medium-emphasis mt-1 mr-1 text-left">
                      Procentowy wskaźnik wyrażający koszt
                      zaciągnięcia&nbsp;kredytu w&nbsp;stosunku rocznym
                    </p>
                  </div>
                </div>

                <div class="v-number-container flex flex-row items-start md:basis-1/4"
                     :class="{'bottom-border-mdAndUp': !areDetailsVisible && !props.showPlaceholders}">
                  <div>
                    <p class="headline-2" v-if="!props.showPlaceholders && offer.rrso">
                      {{ `${offer.provisionPercentage}%` }}
                    </p>
                    <text-placeholder v-else/>
                    <div class="body-1">
                      {{ PROVISION }}
                      <l-tooltip :max-width="300">
                        <template #activator>
                          <v-icon
                              :icon="mdiInformationOutline"
                              class="mb-1"
                              :size="16"
                          />
                        </template>
                        {{ getMortgageElementDescription(PROVISION) }}
                      </l-tooltip>
                    </div>
                    <p v-if="areDetailsVisible"
                       class="body-2 text-medium-emphasis mt-1 mr-1 text-left">
                      Dodatkowa opłata, którą musisz ponieść przed uruchomieniem
                      kredytu hipotecznego
                    </p>
                  </div>
                </div>

                <div class="v-number-container flex flex-row items-start md:basis-1/4"
                     :class="{'bottom-border-mdAndUp': !areDetailsVisible && !props.showPlaceholders}">
                  <div>
                    <p class="headline-2" v-if="!props.showPlaceholders && offer.rrso">
                      {{ `${offer.rrso}%` }}
                    </p>
                    <text-placeholder v-else/>
                    <div class="body-1 min-w-[60px]">
                      {{ RRSO }}
                      <l-tooltip :max-width="300">
                        <template #activator>
                          <v-icon
                              :icon="mdiInformationOutline"
                              class="mb-1"
                              :size="16"
                          />
                        </template>
                        {{ getMortgageElementDescription(RRSO) }}
                      </l-tooltip>
                    </div>
                    <p v-if="areDetailsVisible" class="body-2 text-medium-emphasis mt-1 mr-1 text-left">
                      Pozwala porównać, która oferta jest najtańsza
                      uwzględniając wszystkie dodatkowe koszta.
                    </p>
                  </div>
                </div>

                <div class="v-number-container flex flex-row items-start md:basis-1/4"
                     :class="{'bottom-border-mdAndUp': !areDetailsVisible && !props.showPlaceholders}">
                  <div>
                    <p class="headline-2" v-if="!props.showPlaceholders && offer.rrso">
                      {{ `${toCurrency(offer.installment)}` }}
                    </p>
                    <text-placeholder v-else/>
                    <div class="body-1">
                      {{ MONTHLY_INSTALLMENT }}
                      <l-tooltip :max-width="300">
                        <template #activator>
                          <v-icon
                              :icon="mdiInformationOutline"
                              class="mb-1"
                              :size="16"
                          />
                        </template>
                        {{ getMortgageElementDescription(MONTHLY_INSTALLMENT) }}
                      </l-tooltip>
                    </div>
                    <p v-if="areDetailsVisible" class="body-2 text-medium-emphasis mt-1 mr-1 text-left">
                      Miesięczny koszt dla&nbsp;docelowego oprocentowania.
                    </p>
                  </div>
                </div>
              </div>

              <div
                  @click="toggleDetails"
                  class="flex flex-row max-md:items-center max-md:justify-between md:items-start md:justify-end px-0
                  max-md:py-3 md:w-[50px] md:min-w-[50px] md:max-w-[50px]"
                  :class="{'bottom-border-mdAndUp': !areDetailsVisible && !props.showPlaceholders}">
                <span class="md:hidden body-1">Szczegóły oferty</span>
                <l-btn
                    :icon="true"
                    size="small"
                    type="secondary"
                    variant="text"
                >
                  <v-icon
                      :icon="areDetailsVisible ? mdiChevronUp : mdiChevronDown"
                      size="36"
                      class="max-md:!h-5 md:!h-9 max-md:!w-5 md:!w-9"
                  />
                </l-btn>
              </div>
            </v-col>
          </v-row>
          <template v-if="!props.showPlaceholders">
            <LazySimpleOfferCardDetails
                v-if="areDetailsVisible"
                :hide-installments="readonly"
                :offer="offer"
                :loan-period="loanPeriod"
                class="details-card !p-0 mt-4 md:hidden"
                :type="props.type"
                :representative-example="representativeExample"
            />
            <v-row class="md:hidden">
              <v-col cols="12">
                <slot name="actions"/>
              </v-col>
              <v-col cols="12" class="md:hidden pt-3 flex flex-col items-center">
                <SimulationOfferCounter :counter="offer.loanApplicationsCount"/>
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
    </v-card-text>
    <LazySimpleOfferCardDetails v-if="!props.showPlaceholders && areDetailsVisible"
                                :hide-installments="readonly"
                                :offer="offer"
                                :loan-period="loanPeriod"
                                class="details-card p-4 max-md:hidden md:block"
                                :type="props.type"
                                :representative-example="representativeExample"
    />
    <v-card-actions v-if="!props.showPlaceholders && $slots.actions" class="max-md:hidden md:block p-4 pt-3">
      <v-row>
        <v-col class="pt-0">
          <slot name="actions"/>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts" setup>
import type {SimpleOffer} from "~/models/simulation/simpleSimulation/SimpleOffer";
import {toCurrency} from "~/filters/Filters";
import {ProductType, type SimulationProductType} from "~/enums/ProductType";
import {mdiBank, mdiChevronDown, mdiChevronUp, mdiInformationOutline} from "@mdi/js";
import {
  getMortgageElementDescription,
  MONTHLY_INSTALLMENT,
  PROVISION,
  RATE_OF_INTEREST,
  RRSO
} from "~/dicts/MortgageElements";
import {medalColors} from "~/plugins/vuetify";
import TextPlaceholder from "~/components/TextPlaceholder.vue";
import LTooltip from "~/components/LComponents/LTooltip.vue";
import type {Bank} from "~/models/bank";
import {DEFAULT_REPRESENTATIVE_EXAMPLE} from "~/service/constants";
import useSchema from "~/composables/useSchema";

const props = withDefaults(
    defineProps<{
      index?: number;
      offer: Partial<SimpleOffer>;
      showPlaceholders?: boolean;
      dense?: boolean;
      readonly?: boolean;
      type: SimulationProductType;
      loanPeriod?: number;
      state?: any;
      bank: Bank;
    }>(),
    {
      dense: false,
      showPlaceholders: false,
    }
);

const isMortgage = computed<boolean>(() => props.type === ProductType.MORTGAGE)
const bankImgUrl = computed<string | null>(() => {
  return props.bank?.logoUrl ?? null;
});
const representativeExample = computed<string>(() => {
  return props.bank?.representativeExamples?.[props.type] || DEFAULT_REPRESENTATIVE_EXAMPLE;
});

const offerCardTitle = computed<string>(
    () =>
        `${props.offer.bankName} • Kredyt ${
            isMortgage.value
                ? "hipoteczny"
                : "gotówkowy"
        }`
);

const areDetailsVisible = ref<boolean>(false);
const toggleDetails = () => {
  areDetailsVisible.value = !areDetailsVisible.value;
};

const offerNumberColor = (index: number) => {
  switch (index) {
    case 0:
      return medalColors.gold;
    case 1:
      return medalColors.silver;
    case 2:
      return medalColors.bronze;
    default:
      return "rgba(35, 45, 59, 0.07)";
  }
};

const initialInterestRateSum = computed<string>(() => {
  const value =
      props.offer.changingMargin &&
      props.offer.changingMargin.initialInterestRateSum
          ? props.offer.changingMargin.initialInterestRateSum
          : props.offer.interestRate.sum;
  return `${value}%`;
});
const isInterestRateFixed = computed<boolean>(
    () => props.offer.changingMargin?.initialValueStatic
);
const numberOfYearsOnFixedInterestRate = computed<number>(
    () => props.offer.changingMargin?.initialPeriod / 12
);

</script>

<style scoped lang="scss">
@import "assets/styles/vuetify/base/breakpoints/_breakpoints.scss";

$border-light: 1px solid rgba(var(--v-border-color), var(--v-low-emphasis-opacity));

@media (max-width: #{map-get($breakpoints, md)}) {
  .v-number-container {
    border-bottom: $border-light;
    padding-bottom: 0.75rem !important;
    padding-top: 0.75rem !important;
  }

  .v-number-container:nth-child(even) {
    border-left: $border-light;
    padding-left: 0.75rem !important;
  }

  :deep(.avatar-outline) {
    outline-width: 2px !important;
  }
}

@media (min-width: #{map-get($breakpoints, md)}) {
  .v-number-container {
    padding-left: 1rem !important;
    padding-bottom: 0.75rem !important;
  }

  .v-number-container:not(:first-child) {
    border-left: $border-light;
  }

  .v-number-container:last-child {
    border-right: $border-light;
  }

  :deep(.avatar-outline) {
    outline-width: 4px !important;
  }

  .bottom-border-mdAndUp {
    border-bottom: $border-light;
  }
}
.offerNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  height: 52px;
  width: 52px;
  border-radius: 50%;
  background: radial-gradient(
          53.57% 53.57% at 22.32% 13.39%,
          rgba(255, 255, 255, 0.6) 0%,
          rgba(255, 255, 255, 0) 100%
  );
}

.chip {
  background: rgba(23, 44, 87, 0.12);
  padding: 2px 8px;
  gap: 8px;
  height: 1.75rem;
  border-radius: 16px;
}

.text-placeholder {
  margin-top: 6px;
  margin-bottom: 8px;
  width: 56px;
}

:deep(.v-card-actions .v-btn) {
  padding: 0 16px;
}
</style>
