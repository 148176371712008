export const RATE_OF_INTEREST = "Oprocentowanie" as const;
export const PROVISION = "Prowizja" as const;
export const RRSO = "RRSO" as const;
export const MONTHLY_INSTALLMENT = "Rata miesięczna" as const;
export const mortgageElements: { name: string, description: string }[] = [
    {
        name: RATE_OF_INTEREST,
        description: `Oprocentowanie stałe daje pewność stałej raty, niezależnie od zmian stopy
  procentowej w okresie obowiązywania stałego oprocentowania. Zmienne oprocentowanie bazuje na aktualnie obowiązujących
  stopach procentowych, zatem w trakcie trwania kredytu oprocentowanie wzrośnie lub spadnie.`,
    },
    {
        name: PROVISION,
        description: `Jednorazowa, dodatkowa opłata pobierana przez bank z tytułu udzielenia kredytu hipotecznego.
    Jest wyliczana jako procent od łącznej kwoty udzielonego kredytu. Czasami warto wybrać ofertę z prowizją,
    jeśli inne elementy kredytu są korzystne.`,
    },
    {
        name: RRSO,
        description: `Realna roczna stopa oprocentowana, czyli wyrażony w procentach koszt kredytu uwzględniający
    oprocentowanie, prowizję, koszty obowiązkowych ubezpieczeń, koszty wyceny nieruchomości, oraz inne opłaty niezbędne
    do udzielenia kredytu.
`,
    }, {
        name: MONTHLY_INSTALLMENT,
        description: `Kwota jaką co miesiąc będziesz płacić z tytułu udzielonego kredytu. W ramach raty miesięcznej spłacasz
     zarówno odsetki naliczone przez bank, jak i zmniejszasz zaciągnięte zobowiązanie. Im krótszy okres kredytowania,
      tym proporcjonalnie mniej zapłacisz odsetek.`,
    },
];

export const getMortgageElementDescription = (elementName: string) => {
    return mortgageElements
        .find((element) => element.name === elementName)?.description;
};
